import React, { useState, useEffect } from "react";
import { List, Space } from "antd";
import { FaMap } from "react-icons/fa";
import { HeartOutlined, HomeOutlined } from "@ant-design/icons";
import ProfileDemo from "../component/profileDemo";
import LikeButton from "../shared/Liked";
import "./css/listing.css";
import { Link } from "react-router-dom";

function Home(props) {
  const [propertyData, setPropertyData] = useState(props.property);

  useEffect(() => {
    setPropertyData(props.property);
  }, [props.property]);

  const handleLike = (propertyId, newLikedCount) => {
    const updatedPropertyData = propertyData.map((property) => {
      if (property.id === propertyId) {
        return { ...property, likecount: newLikedCount };
      }
      return property;
    });
    setPropertyData(updatedPropertyData);
  };

  const toggleDescription = (propertyId) => {
    const updatedPropertyData = propertyData.map((property) => {
      if (property.id === propertyId) {
        return {
          ...property,
          showFullDescription: !property.showFullDescription,
        };
      }
      return property;
    });
    setPropertyData(updatedPropertyData);
  };

  const data =
    Array.isArray(props.property) && props.property.length > 0
      ? propertyData.map((value, i) => ({
          id: value.id,
          userId: value.userId,
          href: `/viewproperty/${value.permalink}`,
          title: value.propertytitle,
          avatar: <ProfileDemo userId={value.userId} />,
          description: value.propertydescription,
          propertyImage: value.images,
          price: value.propertyprice,
          city: value.propertycity,
          subtype: value.propertysubtype,
          likecount: value.likecount,
          showFullDescription: value.showFullDescription || false,
        }))
      : [];

  const IconText = ({ icon, text }) => (
    <Space className="icon-text">
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div className="homepage">
      <div className="listData">
        <List
          className="list"
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: props.pagesize,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              className="property-list"
              key={item.title}
              extra={
                item.propertyImage?.length > 0 ? (
                  <img
                    style={{ width: "300px", height: "200px" }}
                    id="login_img"
                    src={`https://nodeapp.merapakghar.com/${item.propertyImage[0]}`}
                    alt="Image not Displaying"
                  />
                ) : (
                  <img
                    style={{ width: "300px", height: "200px" }}
                    id="login_img"
                    src={"./img/imgbackground.jpg"}
                    alt=""
                  />
                )
              }
            >
              <List.Item.Meta
                avatar={item.avatar}
                title={
                  <a className="title" href={item.href}>
                    {item.title}
                  </a>
                }
                description={
                  <div >
                    {item.showFullDescription ? (
                      <>
                        <p className="description">{item.description}</p>
                        <a
                          style={{ color: "blue" }}
                          onClick={() => toggleDescription(item.id)}
                        >
                          Show Less
                        </a>
                      </>
                    ) : (
                      <>
                        <p className="description">
                          {item.description.slice(0, 150)}{" "}
                          {/* Display first 150 characters */}
                          {item.description.length > 150 && (
                          <a
                            style={{ color: "blue"}}
                            onClick={() => toggleDescription(item.id)}
                          >
                            Show More...
                          </a>
                        )}
                        </p>
                       
                      </>
                    )}
                  </div>
                }
              />
              <div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      color: "#2e307d",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Rs. {item.price}
                  </p>
                  <div
                    style={{
                      marginTop: "-10px",
                      marginLeft: "50px",
                      cursor: "pointer",
                    }}
                  ></div>
                </div>
                <div style={{ display: "flex" }}>
                  <Link to={item.href}>
                    <button className="btn view-detail">View Detail</button>
                  </Link>
                  <Link to={`/viewprofile/${item.userId}`}>
                    <button className="btn view-profile">View Profile</button>
                  </Link>
                </div>
              </div>
              <div className="icons">
                <div className="sub-icons s1">
                  <LikeButton
                    id={item.id}
                    likedCount={item.likecount}
                    onLike={handleLike}
                  />
                  <p>{item.likecount}</p>
                </div>

                <div className="sub-icons s2">
                  <FaMap />
                  <p>{item.city}</p>
                </div>

                <div className="sub-icons s3">
                  <HomeOutlined size={20} />
                  <p>{item.subtype}</p>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default Home;
