import React, { useState, useContext, useEffect, useRef } from "react";
import { FaBed, FaBath, FaCar, FaMapMarkerAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./css/AddProperty.css";
import { Modal, Collapse, Switch, Button, Tabs } from "antd";
import axios from "axios";
import { Formik } from "formik";
import Carousel from "react-bootstrap/Carousel";
import NumberToWord from "../shared/NumberToWord";
import { AuthContext } from "../shared/context/auth-context";
import Chip from "@material-ui/core/Chip";
import Datalist from "../shared/datalist";
import { FaUserFriends, FaSellcast } from "react-icons/fa";
import { Upload } from "antd";
import Footer from "../shared/Footer";
const { TabPane } = Tabs;
const { Panel } = Collapse;
function AddProperty(props) {
  const auth = useContext(AuthContext);
  const userId = auth.userId;
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const phoneNoRegex = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const currentDate = new Date().getTime().toString();
  const randomNumber = parseInt(currentDate.substr(currentDate.length - 5));
  const onChange = (key) => {};
  const navigate = useNavigate();
  const byear = new Date().getFullYear() - 20;
  const years = Array.from(new Array(20), (val, index) => index + byear);
  const [userData, setUserData] = useState({});
  const [propertyPurpose, setPropertyPurpose] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [propertySubType, setPropertySubType] = useState("");
  const [propertyPrimaryDetaile, setPropertyPrimaryDetaile] = useState([]);
  const [propertySecondaryDetaile, setPropertySecondaryDetaile] = useState([]);
  const [propertyServices, setPropertyServices] = useState([]);
  const [propertyEntertainment, setPropertyEntertainment] = useState([]);
  const [propertyNearByLandmarks, setPropertyNearByLandmarks] = useState([]);
  // const [detail, setDetail] = useState("");
  const [count, setCount] = useState("1");
  const [bedCounter, setBedCounter] = useState(0);
  const [bathroomCounter, setBathroomCounter] = useState(0);
  const [parkingCounter, setParkingCounter] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [propertyImages, setPropertyImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [signup, setSignup] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [allpropertytypes, setAllPropertyType] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [alldetails, setAllDetails] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const footerRef = useRef(null);
  const propertyDetailsRef = useRef(null);
  const storedData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/user/${storedData.userId}`
        );
        setUserData(data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/allpropertytypes`
      );
      setAllPropertyType(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/alldetails`
      );
      setAllDetails(data);
    };
    fetchData();
  }, []);

  const handleTabChange = (key) => {
    setSelectedPropertyType(key);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOTPModalClose = () => {
    setOtpModal(false);
  };
  const handleNewPasswordModalClose = () => {
    setNewPasswordModal(false);
  };
  const handleAddDetails = () => {
    setIsModalOpen(true);
  };
  const handlePrimaryDetails = (detailsType, detailsCount) => {
    if (detailsCount) {
      const existingDetail = propertyPrimaryDetaile.find(
        (detail) => detail.detailType === detailsType
      );

      if (existingDetail) {
        existingDetail.detailTypeCount = detailsCount;
        setPropertyPrimaryDetaile([...propertyPrimaryDetaile]);
      } else {
        const newDetail = {
          detailType: detailsType,
          detailTypeCount: detailsCount,
        };
        setPropertyPrimaryDetaile((prevDetails) => [...prevDetails, newDetail]);
      }
    } else {
      setPropertyPrimaryDetaile((prevDetails) =>
        prevDetails.filter((d) => d.detailType !== detailsType)
      );
      setSelectedOption((prevDetail) =>
        prevDetail.filter((d) => d !== detailsType)
      );
    }
  };

  const handleSecondaryDetails = (detailtype) => {
    if (propertySecondaryDetaile.includes(detailtype)) {
      setPropertySecondaryDetaile((prevDetails) =>
        prevDetails.filter((f) => f !== detailtype)
      );
    } else {
      setPropertySecondaryDetaile((prevDetails) => [
        ...prevDetails,
        detailtype,
      ]);
    }
  };

  const handleServices = (propertyService) => {
    if (propertyServices.includes(propertyService)) {
      setPropertyServices((prevService) =>
        prevService.filter((f) => f !== propertyService)
      );
    } else {
      setPropertyServices((prevService) => [...prevService, propertyService]);
    }
  };

  const handleEntertainment = (entertainmentService) => {
    if (propertyEntertainment.includes(entertainmentService)) {
      setPropertyEntertainment((prevDetails) =>
        prevDetails.filter((f) => f !== entertainmentService)
      );
    } else {
      setPropertyEntertainment((prevDetails) => [
        ...prevDetails,
        entertainmentService,
      ]);
    }
  };

  const handleNearByLandmarks = (nearByLandmarks) => {
    if (propertyNearByLandmarks.includes(nearByLandmarks)) {
      setPropertyNearByLandmarks((prevDetails) =>
        prevDetails.filter((f) => f !== nearByLandmarks)
      );
    } else {
      setPropertyNearByLandmarks((prevDetails) => [
        ...prevDetails,
        nearByLandmarks,
      ]);
    }
  };

  const handleCount = (event, detail) => {
    setCount(event.target.value);
    if (event.target.value) {
      setCount(event.target.value);
      setSelectedOption((prevDetails) => [...prevDetails, detail]);
      handlePrimaryDetails(detail, event.target.value);
    } else {
      setSelectedOption((prevDetails) => [...prevDetails, ""]);
      handlePrimaryDetails(detail, event.target.value);
    }
  };
  const handlerArray = [
    null,
    handleSecondaryDetails,
    handleServices,
    handleEntertainment,
    handleNearByLandmarks,
  ];

  const setDetail = (type, index) => {
    if (index >= 1 && index < handlerArray.length) {
      handlerArray[index](type);
    }
  };

  const handleDelete = (chipToDelete) => () => {
    let exist;
    setPropertyPrimaryDetaile((prevChips) =>
      prevChips.filter((c) => c.detailType !== chipToDelete)
    );

    propertySecondaryDetaile.forEach((detail, i) => {
      setPropertySecondaryDetaile((prevChips) =>
        prevChips.filter((c) => c.detailType !== chipToDelete)
      );

      if (detail === chipToDelete) {
        propertySecondaryDetaile.splice(i, 1);
        exist = true;
        return true; // stop searching
      }
      return exist;
    });
    propertyServices.forEach((service, i) => {
      if (service === chipToDelete) {
        propertyServices.splice(i, 1);
        exist = true;
        return true; // stop searching
      }
      return exist;
    });
    propertyEntertainment.forEach((entertainment, i) => {
      if (entertainment === chipToDelete) {
        propertyEntertainment.splice(i, 1);
        exist = true;
        return true; // stop searching
      }
      return exist;
    });
    propertyNearByLandmarks.forEach((landmark, i) => {
      if (landmark === chipToDelete) {
        propertyNearByLandmarks.splice(i, 1);
        exist = true;
        window.location.reload();
        return true; // stop searching
      }
      return exist;
    });
  };
  const handleUpload = (uploadedFiles) => {
    setPropertyImages(uploadedFiles.fileList);
  };

  useEffect(() => {
    const propertyDetailsContainer = document.querySelector(
      ".property-details-container"
    );
    const footer = document.querySelector(".footer");
    if (!propertyDetailsContainer || !footer) {
      // If either element is not found, exit the function
      return;
    }
    function handleScroll() {
      const footerTop = footer.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (footerTop < windowHeight) {
        propertyDetailsContainer.style.position = "absolute";
        propertyDetailsContainer.style.bottom = `${windowHeight - footerTop}px`;
      } else {
        propertyDetailsContainer.style.position = "fixed";
        propertyDetailsContainer.style.bottom = "auto";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleSignupModalClose = () => {
    setSignup(false);
  };

  return (
    <div className={isMobileView ? "" : "container-xxl"}>
      <div class="py-5">
        <div class="container">
          <div class="row g-7" className="main" style={{ marginBottom: "10%" }}>
            <Formik
              initialValues={{
                propertypurpose: "",
                propertytype: "",
                propertysubtype: "",
                propertysize: 5,
                propertysizetype: "Marla",
                propertyprice: 0,
                propertytitle: "",
                propertydescription: "",
                propertycity: "",
                propertycityarea: "",
                propertylocation: "",
                propertybuiltyear: "",
                permalink: "",
                propertynoofbedroom: 0,
                propertynoofbathroom: 0,
                propertynoofcarparking: 0,
                images: [],
                propertyPrimaryDetaile: [],
                propertySecondaryDetaile: [],
                propertyServices: [],
                propertyEntertainment: [],
                propertyNearByLandmarks: [],
                numberoffloors: "",
                unitfloor: "",
                name: auth.name,
                phonenumber: userData.phoneno,
                featured: false,
                verified: false,
                likecount: 0,
                userId: auth.userId,
                propertyaddeddate: formattedDate,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.propertypurpose) {
                  errors.propertypurpose = "Property Purpose Required";
                }

                if (!propertyType) {
                  errors.propertytype = "Property Type Required";
                }
                if (!propertySubType) {
                  errors.propertysubtype = "Required";
                }
                if (!values.propertysize) {
                  errors.propertysize = "Property Size Required";
                }
                if (values.propertysizetype === "") {
                  errors.propertysizetype = "Size Type Required";
                }
                if (!values.propertyprice) {
                  errors.propertyprice = "Property Price Required";
                }
                if (!values.propertytitle) {
                  errors.propertytitle = "Property Title Required";
                } else if (values.propertytitle.length < 20) {
                  errors.propertytitle =
                    "Property Title must be of 20 atleast characters";
                } else if (values.propertytitle.length >= 50) {
                  errors.propertytitle =
                    "Property Title must be less than 50 write more details in description.";
                }
                if (!values.propertydescription) {
                  errors.propertydescription = "Property Description Required";
                } else if (values.propertydescription.length < 20) {
                  errors.propertydescription =
                    "Property Description must be of atleast 20 characters";
                }
                // if (!city) {
                //   errors.propertylocation = "Property City Required";
                // } else if (!area) {
                //   errors.propertylocation = "Property City Area Required";
                // } else if (!values.propertylocation) {
                //   errors.propertylocation = "Property Location Required";
                // } else if (values.propertylocation.length < 7) {
                //   errors.propertylocation =
                //     "Property Location must be of atleast 7 characters";
                // }
                // if (!values.phonenumber) {
                //   errors.phonenumber = "Required";
                // } else if (!phoneNoRegex.test(values.phonenumber)) {
                //   errors.phonenumber = "Invalid Phone No";
                // }
                // if (!values.name) {
                //   errors.name = "Required";
                // } else if (values.name.length < 3) {
                //   errors.name = "Name At least of 3 characters";
                // }

                return errors;
              }}
              onSubmit={(values, actions) => {
                const permalinkstring =
                  values.propertytitle.replace(/\s+/g, "-") + randomNumber;
                const formData = new FormData();
                formData.append("propertypurpose", values.propertypurpose);
                formData.append(
                  "propertytype",
                  (values.propertytype = propertyType)
                );
                formData.append(
                  "propertysubtype",
                  (values.propertysubtype = propertySubType)
                );
                formData.append("propertysize", values.propertysize);
                formData.append("propertysizetype", values.propertysizetype);
                formData.append("propertyprice", values.propertyprice);
                formData.append("propertytitle", values.propertytitle);
                formData.append(
                  "propertydescription",
                  values.propertydescription
                );
                formData.append("propertycity", (values.propertycity = city));
                formData.append(
                  "propertycityarea",
                  (values.propertycityarea = area)
                );
                formData.append("propertylocation", values.propertylocation);
                formData.append("propertybuiltyear", values.propertybuiltyear);
                formData.append(
                  "permalink",
                  (values.permalink = permalinkstring)
                );

                formData.append(
                  "propertynoofbedroom",
                  (values.propertynoofbedroom = bedCounter)
                );
                formData.append(
                  "propertynoofbathroom",
                  (values.propertynoofbathroom = bathroomCounter)
                );
                formData.append(
                  "propertynoofcarparking",
                  (values.propertynoofcarparking = parkingCounter)
                );
                propertyPrimaryDetaile.map((detail) => {
                  formData.append(
                    "propertyPrimaryDetaile",
                    JSON.stringify(detail)
                  );
                });
                propertySecondaryDetaile.map((detail) => {
                  formData.append(
                    "propertySecondaryDetaile",
                    (values.propertySecondaryDetaile = detail)
                  );
                });

                propertyServices.map((service) => {
                  formData.append(
                    "propertyServices",
                    (values.propertyServices = service)
                  );
                });

                propertyEntertainment.map((entertainment) => {
                  formData.append(
                    "propertyEntertainment",
                    (values.propertyEntertainment = entertainment)
                  );
                });

                propertyNearByLandmarks.map((nearby) => {
                  formData.append(
                    "propertyNearByLandmarks",
                    (values.propertyNearByLandmarks = nearby)
                  );
                });

                formData.append("numberoffloors", values.numberoffloors);
                formData.append("unitfloor", values.unitfloor);
                formData.append("name", userData.name);
                formData.append("phonenumber", userData.phoneno);
                formData.append("featured", (values.featured = false));
                formData.append("likecount", values.likecount);
                formData.append("userId", (values.userId = auth.userId));
                formData.append(
                  "propertyaddeddate",
                  (values.propertyaddeddate = formattedDate)
                );
                propertyImages.map((image) => {
                  formData.append("images", image.originFileObj);
                });

                if (auth.isLoggedIn === false) {
                  setShowModal(true);
                } else if (auth.isLoggedIn === true) {
                  return Modal.confirm({
                    title: "Are you sure, you want to submit ?",
                    cancelText: "No",
                    okText: "Yes",
                    okType: "primary",
                    onOk: async () => {
                      try {
                        const response = await axios({
                          method: "post",
                          // url:"http://localhost:5000/property/addproperty",
                          url: `${process.env.REACT_APP_BASE_URL}/property/addproperty`,
                          data: formData,

                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                          withCredentials: true,
                        });
                        if (response.data.message) {
                          Modal.warning({
                            title: response.data.message,
                          });
                        } else {
                          if (response.status === 200) {
                            Modal.success({
                              title: response.data,
                            });
                            navigate(`/`);
                          }
                        }
                      } catch (err) {
                        const message = err || "error";
                        alert("Something went wrong,Please try again!");
                        // alert(err.response.data.message, err.response.status);
                      }
                    },
                  });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    className="form-start"
                  >
                    <div className="add_property" style={{ marginTop: "50px" }}>
                      <h3 style={{ paddingBottom: "10px" }}>Add Property</h3>

                      <div class="wow fadeInUp" data-wow-delay="0.5s">
                        <div class="row g-4">
                          <h6 style={{ paddingTop: "10px" }}>
                            Select the purpose of adding your property
                          </h6>

                          <button
                            name="propertypurpose"
                            className={
                              values.propertypurpose === "sale"
                                ? "property_purpose1 col-3"
                                : "property_purpose col-3"
                            }
                            type="button"
                            onClick={() => {
                              setPropertyPurpose("sale");
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={(values.propertypurpose = propertyPurpose)}
                          >
                            <FaSellcast size={25} />
                            Sale
                          </button>
                          <button
                            name="propertypurpose"
                            className={
                              values.propertypurpose === "rent"
                                ? "property_purpose1 "
                                : "property_purpose "
                            }
                            type="button"
                            onClick={() => {
                              setPropertyPurpose("rent");
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={(values.propertypurpose = propertyPurpose)}
                          >
                            <FaUserFriends size={25} />
                            Rent
                          </button>
                        </div>
                        <p style={{ color: "red" }}>
                          {errors.propertypurpose &&
                            touched.propertypurpose &&
                            errors.propertypurpose}
                        </p>
                      </div>

                      <div class="wow fadeInUp" data-wow-delay="0.5s">
                        <h6 style={{ paddingTop: "80px" }}>
                          Select the Type of Property
                        </h6>
                        <div
                          class="row g-5"
                          style={{ paddingTop: "20px", paddingLeft: "40px" }}
                        >
                          <Tabs
                            activeKey={selectedPropertyType}
                            onChange={handleTabChange}
                          >
                            {allpropertytypes.map((type, index) => (
                              <TabPane
                                tab={
                                  <span
                                    className="spanClass"
                                    onClick={() => {
                                      setPropertyType(type.propertytype);
                                    }}
                                  >
                                    {type.propertytype}
                                  </span>
                                }
                                key={index}
                                style={{ width: "80%", height: "auto" }}
                              >
                                {type.propertysubtype.map(
                                  (subtype, subIndex) => (
                                    <button
                                      style={{ marginTop: "10px" }}
                                      class="col-3"
                                      name="propertysubtype"
                                      className={
                                        subtype === propertySubType
                                          ? "property_subtype1"
                                          : "property_subtype"
                                      }
                                      type="button"
                                      onClick={() => {
                                        setPropertySubType(subtype);
                                      }}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // value={(values.propertysubtype = subtype)}
                                    >
                                      {/* <FaHome size={25} /> */}
                                      {subtype}
                                    </button>
                                    // <div key={subIndex}>{subtype}</div>
                                  )
                                )}
                              </TabPane>
                            ))}
                            <p style={{ color: "red" }}>
                              {errors.propertytype &&
                                touched.propertytype &&
                                errors.propertytype}
                            </p>
                          </Tabs>
                          {/* <p style={{ color: "red" }}>
                            {errors.propertytype &&
                              touched.propertytype &&
                              errors.propertytype}
                          </p> */}
                        </div>
                      </div>

                      <div className="size_price py-5">
                        <div style={{ paddingTop: "10px" }}>
                          <h6>Add the size and price of your property</h6>
                          <br></br>
                          <div class="row g-4">
                            <div class="col-md-12">
                              <div class="wow fadeInUp" data-wow-delay="0.5s">
                                <div class="row g-3 ">
                                  <div class="col-12">
                                    <div
                                      class="form-floating"
                                      style={{ display: "flex" }}
                                    >
                                      <input
                                        type="number"
                                        step="0.1"
                                        min="0"
                                        defaultValue={5}
                                        class="form-control"
                                        required
                                        id="propertysize"
                                        name="propertysize"
                                        placeholder="Enter Size"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.propertysize}
                                      />
                                      <label for="propertysize">
                                        Enter Size:
                                      </label>
                                      <select
                                        name="propertysizetype"
                                        style={{
                                          color: "#2E307D",
                                          borderTopRightRadius: "5px",
                                          borderBottomRightRadius: "5px",
                                          border: "1px solid #CED4DA",
                                        }}
                                        placeholder="Choose size type."
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.propertysizetype}
                                      >
                                        <option value="">Select</option>
                                        <option value="Marla">Marla</option>
                                        <option value="Feet">Feet</option>
                                        <option value="Yard">Yard</option>
                                        <option value="Meter">Meter</option>
                                        <option value="Kanal">Kanal</option>
                                      </select>
                                    </div>
                                    <p style={{ color: "red" }}>
                                      {(errors.propertysize &&
                                        touched.propertysize &&
                                        errors.propertysize) ||
                                        (errors.propertysizetype &&
                                          touched.propertysizetype &&
                                          errors.propertysizetype)}
                                    </p>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-floating">
                                      <input
                                        type="number"
                                        min="0"
                                        class="form-control"
                                        id="propertyprice"
                                        name="propertyprice"
                                        placeholder="Price"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.propertyprice}
                                      />
                                      <p style={{ color: "red" }}>
                                        {errors.propertyprice &&
                                          touched.propertyprice &&
                                          errors.propertyprice}
                                      </p>
                                      <label for="price">Price:</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="title_description py-5">
                        <div>
                          <h6>Add title and description of your property</h6>
                          <div class="row g-4">
                            <div class="col-md-12">
                              <div class="wow fadeInUp" data-wow-delay="0.5s">
                                <div class="row g-3">
                                  <div class="col-12">
                                    <div class="form-floating">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="propertytitle"
                                        name="propertytitle"
                                        placeholder="Title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.propertytitle}
                                      />
                                      <p style={{ color: "red" }}>
                                        {errors.propertytitle &&
                                          touched.propertytitle &&
                                          errors.propertytitle}
                                      </p>
                                      <label for="propertytitle">Title:</label>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-floating">
                                      <textarea
                                        type="text"
                                        rows="7"
                                        class="form-control"
                                        id="propertydescription"
                                        name="propertydescription"
                                        placeholder="Description"
                                        style={{ height: "200px" }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.propertydescription}
                                      ></textarea>
                                      <p style={{ color: "red" }}>
                                        {errors.propertydescription &&
                                          touched.propertydescription &&
                                          errors.propertydescription}
                                      </p>
                                      <label for="propertydescription">
                                        Description:
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="location py-5">
                        <div>
                          <h6>Add the location of your property</h6>
                          <div class="row g-4">
                            <div class="col-md-12">
                              <div class="wow fadeInUp" data-wow-delay="0.5s">
                                <div class="row g-3">
                                  <Datalist
                                    setCity={setCity}
                                    setArea={setArea}
                                  />
                                  <div class="col-lg-10 col-md-10 col-sm-12">
                                    <div class="form-floating">
                                      <input
                                        type="text"
                                        class="form-control "
                                        id="propertylocation"
                                        name="propertylocation"
                                        placeholder="Location"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.propertylocation}
                                      />
                                      <p style={{ color: "red" }}>
                                        {errors.propertylocation &&
                                          touched.propertylocation &&
                                          errors.propertylocation}
                                      </p>
                                      <label for="propertylocation">
                                        Block, street, house no.
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="year_built py-5">
                        <div>
                          <div class="row g-4">
                            <div class="col-md-12">
                              <div class="wow fadeInUp" data-wow-delay="0.5s">
                                <div class="row g-3">
                                  <button
                                    class="col-11"
                                    name="propertynoofcarparking"
                                    className="numberofbedroom"
                                    type="button"
                                  >
                                    <div className="options">
                                      <b>No.of Bedrooms</b>
                                      <div className="options-right">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (bedCounter > 0) {
                                              setBedCounter(bedCounter - 1);
                                            }
                                          }}
                                          className="subtract"
                                        >
                                          -
                                        </button>
                                        <span>{bedCounter}</span>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (bedCounter < 20) {
                                              setBedCounter(bedCounter + 1);
                                            }
                                          }}
                                          className="add"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </button>
                                  <button
                                    class="col-11"
                                    name="propertynoofcarparking"
                                    className="numberofbedroom"
                                    type="button"
                                  >
                                    <div className="options">
                                      <b>No.of Bathrooms</b>
                                      <div className="options-right">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (bathroomCounter > 0) {
                                              setBathroomCounter(
                                                bathroomCounter - 1
                                              );
                                            }
                                          }}
                                          className="subtract"
                                        >
                                          -
                                        </button>
                                        <span>{bathroomCounter}</span>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (bathroomCounter < 20) {
                                              setBathroomCounter(
                                                bathroomCounter + 1
                                              );
                                            }
                                          }}
                                          className="add"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </button>
                                  <button
                                    class="col-11"
                                    name="propertynoofcarparking"
                                    className="numberofbedroom"
                                    type="button"
                                  >
                                    <div className="options">
                                      <b>No.of Car Parking</b>
                                      <div className="options-right">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (parkingCounter > 0) {
                                              setParkingCounter(
                                                parkingCounter - 1
                                              );
                                            }
                                          }}
                                          className="subtract"
                                        >
                                          -
                                        </button>
                                        <span>{parkingCounter}</span>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (parkingCounter < 20) {
                                              setParkingCounter(
                                                parkingCounter + 1
                                              );
                                            }
                                          }}
                                          className="add"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="cover_image py-5">
                        <div>
                          <h6>
                            Add Images and Select thecover Image of your
                            property
                          </h6>

                          <div class="row g-4">
                            <div class="col-md-12">
                              <div
                                className="wow fadeInUp"
                                data-wow-delay="0.5s"
                              >
                                <Upload.Dragger
                                  multiple
                                  accept=".png,.jpg,.jpeg"
                                  defaultFileList={[
                                    {
                                      uid: "-1",
                                      name: "defaultImage",
                                      status: "done",
                                      url: "./img/imgbackground.jpg",
                                    },
                                  ]}
                                  fileList={propertyImages}
                                  onChange={handleUpload}
                                >
                                  <p className="ant-upload-text">
                                    Drag file here OR
                                    <br />
                                    <button
                                      className="btn btn-primary py-2"
                                      type="button"
                                    >
                                      Click Upload
                                    </button>
                                  </p>
                                </Upload.Dragger>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="wow fadeInUp" data-wow-delay="0.5s">
                          <div class="row g-4" style={{ paddingTop: "30px" }}>
                            <h5>Add details about your property</h5>
                            <button
                              type="button"
                              style={{
                                backgroundColor: "#EFFDF5",
                                border: "none",
                                textAlign: "left",
                                fontSize: "20px",
                                color: "#2E307D",
                              }}
                              onClick={handleAddDetails}
                            >
                              + Add Details
                            </button>
                            <Modal
                              title="Property Details"
                              open={isModalOpen}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              width={750}
                              bodyStyle={{
                                height: "650px",
                                overflowY: "scroll",
                                marginLeft: "0px",
                                paddingLeft: "0px",
                              }}
                              okText={"Add"}
                              cancelText={"Back"}
                              footer={null}
                            >
                              <div>
                                {propertyPrimaryDetaile.length > 0 &&
                                  propertyPrimaryDetaile?.map((detail) => (
                                    <Chip
                                      label={`${detail.detailType} ${detail.detailTypeCount}`}
                                      style={{
                                        backgroundColor: "#2E307D",
                                        color: "#ffffff",
                                        padding: "20px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onDelete={handleDelete(detail.detailType)}
                                    />
                                  ))}
                                {propertySecondaryDetaile.length > 0 &&
                                  propertySecondaryDetaile?.map((detail) => (
                                    <Chip
                                      label={detail}
                                      style={{
                                        backgroundColor: "#2E307D",
                                        color: "#ffffff",
                                        padding: "20px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onDelete={handleDelete(detail)}
                                    />
                                  ))}
                                {propertyServices.length > 0 &&
                                  propertyServices?.map((service) => (
                                    <Chip
                                      label={service}
                                      style={{
                                        backgroundColor: "#2E307D",
                                        color: "#ffffff",
                                        padding: "20px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onDelete={handleDelete(service)}
                                    />
                                  ))}
                                {propertyEntertainment.length > 0 &&
                                  propertyEntertainment?.map(
                                    (entertainmentService) => (
                                      <Chip
                                        label={entertainmentService}
                                        style={{
                                          backgroundColor: "#2E307D",
                                          color: "#ffffff",
                                          padding: "20px",
                                          marginRight: "10px",
                                          marginBottom: "10px",
                                          borderRadius: "10px",
                                        }}
                                        onDelete={handleDelete(
                                          entertainmentService
                                        )}
                                      />
                                    )
                                  )}
                                {propertyNearByLandmarks.length > 0 &&
                                  propertyNearByLandmarks?.map(
                                    (nearByLandmarks) => (
                                      <Chip
                                        label={nearByLandmarks}
                                        style={{
                                          backgroundColor: "#2E307D",
                                          color: "#ffffff",
                                          padding: "20px",
                                          marginRight: "10px",
                                          marginBottom: "10px",
                                          borderRadius: "10px",
                                        }}
                                        onDelete={handleDelete(nearByLandmarks)}
                                      />
                                    )
                                  )}
                              </div>

                              <Collapse
                                size="large"
                                onChange={onChange}
                                width={700}
                                style={{
                                  marginTop: "100px",
                                  marginLeft: "0px",
                                  paddingLeft: "0px",
                                }}
                              >
                                {alldetails.map((detail, index) => (
                                  <Panel
                                    header={<div>{detail.detailtype}</div>}
                                    key={index}
                                    className="panel"
                                  >
                                    {detail.detailsubtype.map(
                                      (type, subtypeIndex) => (
                                        <div
                                          className="counter_container"
                                          key={subtypeIndex}
                                        >
                                          <button
                                            className={
                                              (index === 0 &&
                                                selectedOption?.includes(
                                                  type
                                                )) ||
                                              (index > 0 &&
                                                propertySecondaryDetaile?.includes(
                                                  type
                                                )) ||
                                              propertyServices?.includes(
                                                type
                                              ) ||
                                              propertyEntertainment?.includes(
                                                type
                                              ) ||
                                              propertyNearByLandmarks?.includes(
                                                type
                                              )
                                                ? "property_details1"
                                                : "property_details"
                                            }
                                            type="button"
                                            onClick={() => {
                                              setDetail(type, index);
                                            }}
                                          >
                                            <p>{type}</p>

                                            {index === 0 && (
                                              <select
                                                className="propertydetailcounter"
                                                name="propertydetailcounter"
                                                onChange={(event) =>
                                                  handleCount(event, type)
                                                }
                                                onBlur={handleBlur}
                                                value={
                                                  values.propertydetailcounter
                                                }
                                              >
                                                <option value="">choose</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10+">10+</option>
                                              </select>
                                            )}
                                          </button>
                                        </div>
                                      )
                                    )}
                                  </Panel>
                                ))}
                              </Collapse>
                            </Modal>
                            <div>
                              {propertyPrimaryDetaile.length > 0 &&
                                propertyPrimaryDetaile?.map((detail) => (
                                  <Chip
                                    label={`${detail.detailTypeCount} ${detail.detailType} `}
                                    style={{
                                      backgroundColor: "#2E307D",
                                      color: "#ffffff",
                                      padding: "20px",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                      borderRadius: "10px",
                                    }}
                                    onDelete={handleDelete(detail.detailType)}
                                  />
                                ))}
                              {propertySecondaryDetaile.length > 0 &&
                                propertySecondaryDetaile?.map((detail) => (
                                  <Chip
                                    label={detail}
                                    style={{
                                      backgroundColor: "#2E307D",
                                      color: "#ffffff",
                                      padding: "20px",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                      borderRadius: "10px",
                                    }}
                                    onDelete={handleDelete(detail)}
                                  />
                                ))}
                              {propertyServices.length > 0 &&
                                propertyServices?.map((service) => (
                                  <Chip
                                    label={service}
                                    style={{
                                      backgroundColor: "#2E307D",
                                      color: "#ffffff",
                                      padding: "20px",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                      borderRadius: "10px",
                                    }}
                                    onDelete={handleDelete(service)}
                                  />
                                ))}
                              {propertyEntertainment.length > 0 &&
                                propertyEntertainment?.map(
                                  (entertainmentService) => (
                                    <Chip
                                      label={entertainmentService}
                                      style={{
                                        backgroundColor: "#2E307D",
                                        color: "#ffffff",
                                        padding: "20px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onDelete={handleDelete(
                                        entertainmentService
                                      )}
                                    />
                                  )
                                )}
                              {propertyNearByLandmarks.length > 0 &&
                                propertyNearByLandmarks?.map(
                                  (nearByLandmarks) => (
                                    <Chip
                                      label={nearByLandmarks}
                                      style={{
                                        backgroundColor: "#2E307D",
                                        color: "#ffffff",
                                        padding: "20px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onDelete={handleDelete(nearByLandmarks)}
                                    />
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-12">
                          <h6>What is your name?</h6>
                          <div class="form-floating">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              class="form-control"
                              placeholder="Your Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <p style={{ color: "red" }}>
                              {errors.name && touched.name && errors.name}
                            </p>
                            <label for="name">Your Name</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <h6>How to Contact you</h6>
                          <div class="form-floating">
                            <input
                              type="text"
                              name="phonenumber"
                              id="phonenumber"
                              class="form-control"
                              placeholder="Your Phone No"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phonenumber}
                            />
                            <p style={{ color: "red" }}>
                              {errors.phonenumber &&
                                touched.phonenumber &&
                                errors.phonenumber}
                            </p>
                            <label for="phone_no">Your phoneno</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div class="col-2 py-3" className="action_buttons">
                      <button
                        className="submit-btn1 btn btn-primary py-2  action_submit_buttons"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <div className="property-details-container">
                    <div className="property-image-container">
                      <Carousel>
                        {propertyImages.length > 0 ? (
                          propertyImages.map((image) => {
                            return (
                              <Carousel.Item>
                                <img
                                  src={URL.createObjectURL(image.originFileObj)}
                                  alt={image.name}
                                  style={{
                                    height: "300px",
                                    width: "450px",
                                    // objectFit: "contain",
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })
                        ) : (
                          <img
                            style={{ width: "100%" }}
                            id="login_img"
                            src={"./img/imgbackground.jpg"}
                            alt=""
                          />
                        )}
                      </Carousel>
                    </div>
                    <div ref={propertyDetailsRef} className="property-details">
                      <h1 className="property-title">{values.propertytitle}</h1>
                      {values.propertyprice ? (
                        <div
                          style={{
                            display: "flex",
                            fontSize: "1.2rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <p>PKR:</p>
                          <p>
                            <NumberToWord amount={values.propertyprice} />
                          </p>
                        </div>
                      ) : (
                        <p style={{ fontSize: "1.2rem" }}>Price</p>
                      )}

                      <div className="property-location-container">
                        <FaMapMarkerAlt
                          style={{ paddingBottom: "12px", fontSize: "30px" }}
                          className="location-icon"
                        />
                        {values.propertylocation ? (
                          <p className="property-location">
                            {city},{area},{values.propertylocation}
                          </p>
                        ) : (
                          <p className="property-location">Location</p>
                        )}
                      </div>
                      <h5>Description</h5>
                      {values.propertydescription ? (
                        <p className="property-description">
                          {values.propertydescription}
                        </p>
                      ) : (
                        <p>Description about this property comes here</p>
                      )}

                      <h5>Details</h5>
                      <ul className="property-features">
                        <li>
                          <FaBed className="feature-icon" />{" "}
                          {values.propertynoofbedroom} Bedrooms
                        </li>
                        <li>
                          <FaBath className="feature-icon" />{" "}
                          {values.propertynoofbathroom} Bathrooms
                        </li>
                        <li>
                          <FaCar className="feature-icon" />{" "}
                          {values.propertynoofcarparking} Car Parking
                        </li>
                      </ul>
                      <h5>Features</h5>
                      <div className="property-map-container">
                        <div>
                          {propertyPrimaryDetaile?.length > 0 &&
                            propertyPrimaryDetaile?.map((detail) => (
                              <Chip
                                label={`${detail.detailTypeCount} ${detail.detailType} `}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                              />
                            ))}
                          {propertySecondaryDetaile?.length > 0 &&
                            propertySecondaryDetaile?.map((detail) => (
                              <Chip
                                label={detail}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                              />
                            ))}
                          {propertyServices?.length > 0 &&
                            propertyServices?.map((service) => (
                              <Chip
                                label={service}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                              />
                            ))}
                          {propertyEntertainment?.length > 0 &&
                            propertyEntertainment?.map(
                              (entertainmentService) => (
                                <Chip
                                  label={entertainmentService}
                                  style={{
                                    backgroundColor: "#2E307D",
                                    color: "#ffffff",
                                    padding: "20px",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "10px",
                                  }}
                                />
                              )
                            )}
                          {propertyNearByLandmarks?.length > 0 &&
                            propertyNearByLandmarks?.map((nearByLandmarks) => (
                              <Chip
                                label={nearByLandmarks}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>

          <div>
            {!auth.isLoggedIn && showModal && (
              <div>
                <div>
                  <Modal
                    style={{ fontSize: "24px", textAlign: "center" }}
                    title={
                      <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                        Login
                      </h2>
                    }
                    open={showModal}
                    onCancel={handleCloseModal}
                    // maskClosable={false}
                    width={450}
                    footer={null}
                    bodyStyle={{
                      height: "500px",
                      marginTop: "50px",
                      // overflowY: "scroll",
                      marginLeft: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <Formik
                      initialValues={{ email: "", password: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = "Required";
                        } else if (!regex.test(values.email)) {
                          errors.email = "Email is Not valid";
                        }
                        if (!values.password) {
                          errors.password = "Required";
                        } else if (values.password.length < 8) {
                          errors.password = "Password must be of 8 characters";
                        }

                        return errors;
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          const response = await axios({
                            method: "post",
                            // url: 'http://localhost:5000/auth/login',
                            url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
                            data: {
                              email: values.email,
                              password: values.password,
                            },
                            withCredentials: true,
                          });
                          if (response.data.message) {
                            Modal.warning({ title: response.data.message });
                          } else {
                            auth.login(
                              response.data.isLoggedIn,
                              response.data.user._id,
                              response.data.user.name
                            );
                            if (response.status === 200) {
                              Modal.success({
                                title: "User Login Successfully!",
                              });

                              setShowModal(false);
                            }
                          }
                        } catch (err) {
                          alert("Something went wrong,Please try again!");
                        }
                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          id="editmodal"
                          className="w-full max-w-sm"
                        >
                          <div
                            className="md:flex md:items-center mb-6"
                            style={{ paddingTop: "40px" }}
                          >
                            <div
                              class="col-12"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="form-floating">
                                <input
                                  type="email"
                                  name="email"
                                  // id="phone_no"
                                  class="form-control"
                                  placeholder="Your Phone No"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </p>
                                <label for="phone_no">Your Email</label>
                              </div>
                            </div>
                            <div
                              class="col-12"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <div class="form-floating">
                                <input
                                  type="password"
                                  name="password"
                                  class="form-control"
                                  id="password"
                                  placeholder="Your Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.password &&
                                    touched.password &&
                                    errors.password}
                                </p>
                                <label for="password">Your Password</label>
                              </div>
                            </div>
                            <div
                              class="col-12"
                              style={{
                                paddingTop: "60px",
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  textalign: "center",
                                  fontSize: "20px",
                                }}
                              >
                                Don't have an account?
                                <Link
                                  style={{ color: "blue" }}
                                  onClick={() => {
                                    setSignup(true);
                                  }}
                                >
                                  {" "}
                                  SignUp
                                </Link>
                                <Link
                                  style={{
                                    marginLeft: "70px",
                                    textAlign: "center",
                                  }}
                                  onClick={() => {
                                    setOtpModal(true);
                                  }}
                                >
                                  {" "}
                                  Forget Password
                                </Link>
                              </p>
                            </div>
                            <div
                              class="col-12"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "30px",
                              }}
                            >
                              <button
                                class="btn btn-primary w-100 py-3"
                                type="submit"
                                disabled={isSubmitting}
                                // onClick={handleClick}
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Modal>
                  <Modal
                    style={{ fontSize: "24px", textAlign: "center" }}
                    open={otpModal}
                    onCancel={handleOTPModalClose}
                    maskClosable={false}
                    width={450}
                    footer={null}
                    bodyStyle={{
                      height: "200px",
                      marginTop: "50px",
                      marginLeft: "0px",
                      paddingLeft: "0px",
                      textAlign: "center",
                    }}
                  >
                    <Formik
                      initialValues={{ email: "", otp: "" }}
                      validate={(values) => {
                        const errors = {};
                        return errors;
                      }}
                      onSubmit={async (values, actions) => {
                        setEmail(values.email);
                        try {
                          const response = await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_BASE_URL}/auth/send-otp`,
                            data: {
                              email: values.email,
                            },
                          });
                          if (response.data.message) {
                            Modal.warning({
                              title: response.data.message,
                            });
                          } else {
                            if (response.status === 200) {
                              Modal.success({
                                title: response.data,
                              });
                              setNewPasswordModal(true);
                              handleOTPModalClose(); // Close the OTP modal
                            }
                          }
                        } catch (err) {
                          alert("Something went wrong. Please try again!");
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          id="editmodal"
                          className="w-full max-w-sm"
                        >
                          <div
                            className="md:flex md:items-center mb-6"
                            style={{ paddingTop: "40px" }}
                          >
                            <div
                              className="col-12"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                className="form-floating"
                                style={{ width: "70%" }}
                              >
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Your Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </p>
                                <label htmlFor="password">Email</label>
                              </div>
                              <div style={{ width: "30%", margin: "0px" }}>
                                <button
                                  className="btn btn-primary w-full py-2"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Send Email
                                </button>
                              </div>
                            </div>
                            <div>
                              <p>OTP should be sent to Your Email </p>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Modal>
                  <Modal
                    style={{ fontSize: "24px", textAlign: "center" }}
                    open={newPasswordModal}
                    onCancel={handleNewPasswordModalClose}
                    maskClosable={false}
                    width={450}
                    footer={null}
                    bodyStyle={{
                      height: "500px",
                      marginTop: "50px",
                      overflowY: "scroll",
                      marginLeft: "0px",
                      paddingLeft: "0px",
                      textAlign: "center",
                    }}
                  >
                    <Formik
                      initialValues={{
                        otp: "",
                        email: "",
                        password: "",
                        confirmpassword: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.password) {
                          errors.password = "Password Required";
                        } else if (values.password.length < 8) {
                          errors.password = "Password must be of 8 characters";
                        }
                        if (!values.confirmpassword) {
                          errors.confirmpassword = "Confirm Password Required";
                        } else if (values.password !== values.confirmpassword) {
                          errors.confirmpassword = "Incorrect Confirm Password";
                        }
                        return errors;
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          const response = await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_BASE_URL}/auth/submit-otp`,
                            data: {
                              otp: values.otp,
                              email: email,
                              password: values.password,
                            },
                            withCredentials: true,
                          });
                          if (response.data.message) {
                            Modal.warning({
                              title: response.data.message,
                            });
                          } else {
                            if (response.status === 200) {
                              alert("Password Updated.");
                              handleNewPasswordModalClose();
                            }
                          }
                        } catch (err) {
                          const message = err.response.data.message;
                          alert(err.response.data.message, err.response.status);
                        }
                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          id="editmodal"
                          className="w-full max-w-sm"
                        >
                          <div
                            className="md:flex md:items-center mb-6"
                            style={{ paddingTop: "40px" }}
                          >
                            <div
                              className="col-12"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <div className="form-floating">
                                <input
                                  type="otp"
                                  name="otp"
                                  className="form-control"
                                  id="otp"
                                  placeholder="Your Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.otpModal}
                                />

                                <label htmlFor="password">OTP</label>
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "20px",
                              }}
                            >
                              <div className="form-floating">
                                <input
                                  type="password"
                                  name="password"
                                  className="form-control"
                                  id="password"
                                  placeholder="Your Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.password &&
                                    touched.password &&
                                    errors.password}
                                </p>
                                <label htmlFor="password">New Password</label>
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "20px",
                              }}
                            >
                              <div className="form-floating">
                                <input
                                  type="password"
                                  name="confirmpassword"
                                  className="form-control"
                                  id="confirmpassword"
                                  placeholder="Confirm Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirmpassword}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.confirmpassword &&
                                    touched.confirmpassword &&
                                    errors.confirmpassword}
                                </p>
                                <label htmlFor="password">
                                  Confirm Password
                                </label>
                              </div>
                            </div>

                            <div
                              className="col-12"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "30px",
                              }}
                            >
                              <button
                                className="btn btn-primary w-100 py-3"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Modal>
                </div>
                <div>
                  <Modal
                    open={signup}
                    onCancel={handleSignupModalClose}
                    keyboard={false}
                    title={
                      <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                        Signup
                      </h2>
                    }
                    footer={null}
                    width={450}
                    bodyStyle={{
                      marginTop: "50px",
                    }}
                  >
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        phoneno: "",
                        password: "",
                        confirmpassword: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.name) {
                          errors.name = "Required";
                        } else if (values.name.length < 3) {
                          errors.name = "Name should be at least 3 characters";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (!regex.test(values.email)) {
                          errors.email = "Email is not valid";
                        }

                        if (!values.phoneno) {
                          errors.phoneno = "Required";
                        } else if (!phoneNoRegex.test(values.phoneno)) {
                          errors.phoneno = "WhatsApp No. is not valid";
                        }

                        if (!values.password) {
                          errors.password = "Required";
                        } else if (values.password.length < 8) {
                          errors.password = "Password must be of 8 characters";
                        }

                        if (!values.confirmpassword) {
                          errors.confirmpassword = "Required";
                        } else if (values.confirmpassword !== values.password) {
                          errors.confirmpassword = "Passwords do not match";
                        }

                        return errors;
                      }}
                      onSubmit={async (values, actions) => {
                        try {
                          const response = await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_BASE_URL}/auth/signup`,
                            data: {
                              name: values.name,
                              email: values.email,
                              phoneno: values.phoneno,
                              password: values.password,
                            },
                            withCredentials: true,
                          });
                          if (response.data.message) {
                            Modal.warning({
                              title: response.data.message,
                            });
                          } else {
                            if (response.status === 200) {
                              Modal.success({
                                title: response.data,
                              });
                              handleSignupModalClose();
                            }
                          }
                        } catch (err) {
                          const messsage = err.response.data.message;
                          Error(
                            messsage || "Something went wrong,Please try again!"
                          );
                          alert(err.response.data.message, err.response.status);
                          alert(err.response.data.message, err.response.status);
                        }
                      }}
                      // setTimeout(() => {
                      //   alert(JSON.stringify(values, null, 2));
                      //   setSubmitting(false);
                      // }, 400);
                      // }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          id="editmodal"
                          className="w-full max-w-sm"
                        >
                          <div className="md:flex md:items-center mb-6">
                            <div class="col-12">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  name="name"
                                  class="form-control"
                                  id="name"
                                  placeholder="Your Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.name && touched.name && errors.name}
                                </p>
                                {/* {errors.name && touched.name && errors.name} */}
                                <label for="name">Name</label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-floating">
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Your Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </p>
                                <label for="phone">Email</label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="phoneno"
                                  name="phoneno"
                                  placeholder="WhatsApp No."
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phoneno}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.phoneno &&
                                    touched.phoneno &&
                                    errors.phoneno}
                                </p>
                                <label for="password">WhatsApp</label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-floating">
                                <input
                                  type="password"
                                  class="form-control"
                                  id="password"
                                  name="password"
                                  placeholder="Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.password &&
                                    touched.password &&
                                    errors.password}
                                </p>
                                <label for="password">Password</label>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="form-floating">
                                <input
                                  type="password"
                                  class="form-control"
                                  id="confirmpassword"
                                  placeholder="Confirm Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirmpassword}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.confirmpassword &&
                                    touched.confirmpassword &&
                                    errors.confirmpassword}
                                </p>
                                <label for="confirmpassword">
                                  Confirm Password
                                </label>
                              </div>
                            </div>

                            <div class="col-12">
                              <p
                                style={{
                                  textalign: "center",
                                  fontSize: "20px",
                                }}
                              >
                                {" "}
                                Have an account?{" "}
                                <Link
                                  onClick={() => {
                                    handleSignupModalClose();
                                    setShowModal(true);
                                    // setLoginModal(true);
                                  }}
                                >
                                  {" "}
                                  LogIn
                                </Link>
                              </p>
                            </div>
                            <div
                              class="col-12"
                              style={{ paddingBottom: "30px" }}
                            >
                              <button
                                class="btn btn-primary w-100 py-3"
                                type="submit"
                              >
                                SignUp
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Modal>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer ref={footerRef} className="footer" />
    </div>
  );
}
export default AddProperty;
