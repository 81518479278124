import { useState, useEffect } from "react";
import { HomeOutlined, LikeOutlined } from "@ant-design/icons";
import { List, Space } from "antd";
import React from "react";
import { FaMap } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProfileDemo from "../component/profileDemo";
import "./css/listing.css";

function Home(props) {
  const [propertyData, setPropertyData] = useState(props.property);

  useEffect(() => {
    setPropertyData(props.property);
  }, [props.property]);

  const toggleDescription = (propertyId) => {
    console.log("working");
    const updatedPropertyData = propertyData.map((property) => {
      if (property.id === propertyId) {
        return {
          ...property,
          showFullDescription: !property.showFullDescription,
        };
      }
      return property;
    });
    setPropertyData(updatedPropertyData);
  };
  const data =
    Array.isArray(props.property) && props.property.length > 0
      ? propertyData.map((value) => ({
          id: value.id,
          userId: value.userId,
          href: `${`/viewproperty/${value.permalink}`}`,
          title: value.propertytitle,
          avatar: <ProfileDemo userId={value.userId} />,
          description: value.propertydescription,
          propertyImage: value.images,
          price: value.propertyprice,
          city: value.propertycity,
          subtype: value.propertysubtype,
          likecount: value.likecount,
          showFullDescription: value.showFullDescription || false,
        }))
      : [];

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div className="homepage">
      <div className="listData">
        <List
          className="list"
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 5,
          }}
          dataSource={data}
          renderItem={(item) => (
            console.log(item),
            (
              <List.Item
                className="property-list"
                key={item.title}
                actions={[
                  <IconText
                    icon={LikeOutlined}
                    text={item.likecount}
                    key="list-vertical-like-o"
                  />,
                  <IconText
                    icon={FaMap}
                    text={item.city}
                    key="list-vertical-like-o"
                  />,
                  <IconText
                    icon={HomeOutlined}
                    text={item.subtype}
                    key="list-vertical-like-o"
                  />,
                ]}
                extra={
                  item.propertyImage?.length > 0 ? (
                    <img
                      style={{ width: "300px", height: "200px" }}
                      id="login_img"
                      // src={process.env.REACT_APP_BASE_TWO_URL+'/'+item.propertyImage[0]}
                      src={`https://nodeapp.merapakghar.com/${item.propertyImage[0]}`}
                      alt="Image not Displaying"
                    />
                  ) : (
                    <img
                      style={{ width: "300px", height: "200px" }}
                      id="login_img"
                      src={"./img/imgbackground.jpg"}
                      alt=""
                    />
                  )
                }
              >
                <List.Item.Meta
                  // avatar={item.avatar} //user image
                  title={
                    <a
                      style={{
                        color: "#2e307d",
                        fontSize: "22px",
                        fontWeight: "bold",
                        fontFamily: "tahoma",
                      }}
                      href={item.href}
                    >
                      {item.title}
                    </a>
                  }
                  description={
                    <div>
                      {item.showFullDescription ? (
                        <>
                          <p className="description">{item.description}</p>
                          <a
                            style={{ color: "blue" }}
                            onClick={() => toggleDescription(item.id)}
                          >
                            Show Less
                          </a>
                        </>
                      ) : (
                        <>
                          <p className="description">
                            {item.description.slice(0, 150)}{" "}
                            {/* Display first 150 characters */}
                            {item.description.length > 150 && (
                              <a
                                style={{
                                  color: "blue",
                                }}
                                onClick={() => toggleDescription(item.id)}
                              >
                                Show More...
                              </a>
                            )}
                          </p>
                        </>
                      )}
                    </div>
                  }
                />
                {item.content}
                <div>
                  <p
                    style={{
                      color: "#2e307d",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Rs. {item.price}
                  </p>
                  <div style={{ display: "flex" }}>
                    <Link to={item.href}>
                      <button className="btn view-detail">View Detail</button>
                    </Link>
                  </div>
                </div>
              </List.Item>
            )
          )}
        />
      </div>
    </div>
  );
}

export default Home;
