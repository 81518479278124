import React, { useState, Fragment, useRef } from "react";
import { useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/cards.css";
import "./App.css";
import Search from "./shared/Search";
import Footer from "./shared/Footer";
import { Carousel, Button, Tabs } from "antd";
import { FaArrowUp } from "react-icons/fa";
import Listing from "./shared/Listing";
const { TabPane } = Tabs;
const onChange = (key) => {
  console.log(key);
};

function App() {
  const navigate = useNavigate();
  const [property, setProperty] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  const nextSlide = () => {
    carouselRef?.current?.next();
  };

  const prevSlide = () => {
    carouselRef?.current?.prev();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide changes every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/cities`
        );
        setCityData(data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/featured`
      );
      console.log(data);
      setProperty(data);
      console.log(property);
    };
    fetchData();
    console.log(property);
  }, []);

  const items = [
    {
      key: "1",
      label: "For Sale",

      children: cityData.map((data) => (
        <a class="btn " style={{fontSize:"20px"}} href={`/properties/sale/${data.city}`}>
          Properties in {data.city}
        </a>
      )),
    },
    {
      key: "2",
      label: "For Rent",
      children: cityData.map((data) => (
        <a class="btn" style={{fontSize:"20px"}}  href={`/properties/rent/${data.city}`}>
         Properties in {data.city}
        </a>
      )),
    },
  ];
  console.log(property, 111);
  // if (property.length === 0) {
  //   return (
  //     <div id="spinner" style={{backdropFilter: "blur(4px)"}} class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
  //               <div class="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
  //                   <span class="sr-only">Loading...</span>
  //               </div>
  //           </div>
  //   );
  // }
  return (
    <div class="container-xxl bg-white p-0">
      <div class="container-fluid header bg-white p-0">
        <div
          className="row g-0 align-items-center flex-column-reverse flex-md-row"
          style={{ display: "flex", marginTop: "50px" }}
        >
          <div className="col-md-6 p-5 mt-lg-5">
            <h1 className="display-5 animated fadeIn mb-4">
              Find A <span className="text-primary">Perfect Home</span> To Live
              With Your Family
            </h1>
          </div>
          <div className="col-md-6">
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                position: "relative",
                marginTop: "100px",
              }}
            >
              <Carousel
                ref={carouselRef}
                dotPosition="bottom"
                // autoplay={true}
                dotStyle={{ bottom: 10 }}
                afterChange={(current) => setCurrentSlide(current)}
              >
                <div>
                  <img
                    src="./img/carousel-1.jpg"
                    alt="Image 1"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <img
                    src="./img/carousel-2.jpg"
                    alt="Image 2"
                    style={{ width: "100%" }}
                  />
                </div>
              </Carousel>
              <Button
                onClick={prevSlide}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: -30,
                  transform: "translateY(-50%)",
                  background: "#00B98E",
                  border: "1px #00B98E",
                  borderRadius: "50%",
                  padding: "0",
                  width: "60px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LeftOutlined style={{ color: "white", fontSize: "24px" }} />
              </Button>
              <Button
                onClick={nextSlide}
                style={{
                  position: "absolute",
                  top: "40%",
                  left: -30,
                  transform: "translateY(-50%)",
                  background: "#00B98E",
                  border: "1px #00B98E",
                  borderRadius: "50%",
                  padding: "0",
                  width: "60px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RightOutlined style={{ color: "white", fontSize: "24px" }} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="search">
        <Search />
      </div>

      <div className="property-listing-title">
        <div>
  
          <h1 style={{fontFamily: "Poppins,sans-serif"}} >Property Listing</h1>
        </div>
        <div>
          <button
            disabled
            class="btn btn-primary active"
            data-bs-toggle="pill"
            href="/featured"
            style={{ backgroundColor: "#00b98e" }}
          >
            Featured
          </button>
        </div>
      </div>
      <div style={{ margin: "20px" }}>
        <Listing property={property} pagesize={5} />
      </div>

      <div class=" py-5 contact-agent" >
        <div class="container">
          <div class="bg-light rounded p-3">
            <div
              class="bg-white rounded p-4"
              style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
            >
              <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                  <img
                    class="img-fluid rounded w-100"
                    src="./img/call-to-action.jpg"
                    alt=""
                  />
                </div>
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <div class="mb-4">
                    <h1 class="mb-3">Contact With Us</h1>
                    <p>
                      Have a question or ready to take the next step? Reach out
                      to us - we're here to assist! Contact our team today for
                      personalized solutions and exceptional service.
                    </p>
                  </div>
                  <a href="/" class="btn btn-primary py-3 px-4 me-2">
                    <i class="fa fa-phone-alt me-2"></i>Make A Call
                  </a>
                  {/* <a href="/" class="btn btn-dark py-3 px-4">
                    <i class="fa fa-calendar-alt me-2"></i>Get Appoinment
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 style={{marginLeft:"30px"}}>Popular Locations</h3>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="large"
          tabBarStyle={{
            fontSize: "50px",
            fontWeight: "bold",
          }}
          style={{ padding: "50px" }}
          items={items}
          onChange={onChange}
        />
         
        
      </div>

      <Footer />

      <a href="/" class="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i class="bi bi-arrow-up"></i>
        <FaArrowUp />
      </a>
    </div>
  );
}

export default App;
